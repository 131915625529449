import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const query = graphql`
	query {
		strapiPageLaPedagogiePnl {
			title
			slug
			body
			video
			seo {
				metaDescription
				metaTitle
			}
		}
	}
`;

const LaPedagogiePNL = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageLaPedagogiePnl;
	const breakpoints = useBreakpoint();

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className={`grid grid-cols-1 lg:grid lg:grid-cols-2`}>
						<div>{parse(page.body)}</div>
						<div
							className={`player-container ${
								breakpoints.small ? `row-start-1` : ``
							}`}
						>
							<div className="player-wrapper">
								<ReactPlayer
									url={page.video}
									className="react-player"
									width="100%"
									height="100%"
									loop={true}
									controls={true}
								/>
							</div>
						</div>
					</div>
					<div className={`button-action`}>
						<Link
							to="/les-strategies-d-apprentissage-en-pnl"
							className={`link_default`}
						>
							<button className={`btn btn--green`}>
								Vers les stratégies d'apprentissage
							</button>
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default LaPedagogiePNL;
